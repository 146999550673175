<!--<script src="../router/index.ts"></script>-->
<template>
  <div class="form-wrapper" style=" margin-top: 50px;">
    <div class="vertical-center">
      <div class="inner-block">
    <h3>LOGIN to dashboard</h3>
    <form @submit.prevent="login" action="">
      <div v-if="errors" class="errors">
        <p v-for="(error, field) in errors" :key="field">
          {{ error[0] }}
        </p>
      </div>

      <div class="form-group">
        <label>Username</label>
        <input type="text" v-model="username" class="form-control form-control-lg" placeholder="Enter username / email"><br>
      </div>

      <div class="form-group">
        <label>Password</label>
        <div class="input-group">
          <input :type="typeShow" v-model="password" class="form-control form-control-lg" placeholder="Your password">
              <div class="input-group-addon" @click="showPassword" style="cursor:pointer">
                <img :src="icon" width="20" height="20" style="position: absolute; margin-left:-30px; margin-top:15px; opacity: 0.6">
              </div>
        </div><br>
      </div>
      <input type="hidden" v-model="grantType" hidden >
      <input type="hidden" v-model="clientId" hidden >
      <input type="hidden" v-model="clientSecret" hidden >
      <div class="row">
        <div class="form-group">
          <button type="submit" class="form-group btn btn-primary btn-lg col-md-12 form-control">LOGIN</button>
        </div>
        <p class="forgot-password mt-2 mb-4 col-md-6" style="text-align: left;">
          <router-link to="/register" class="link">Not registered ? Click here </router-link>
        </p>

        <p class="forgot-password mt-2 mb-4 col-md-6" style="text-align: right;">
<!--          <a @click="openModal">Forgot password ?</a>-->
          <a @click="goToForgot" style="cursor: pointer; text-decoration: underline">Forgot password ?</a>
        </p>
<!--/*          <teleport to="#forgot">*/-->
<!--&lt;!&ndash;&lt;!&ndash;        <div v-if="modalOpened"  class="modal-area">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <span @click="closeModal" class="close-modal">&times;</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;      </teleport></p>&ndash;&gt;-->
<!--        <div id="forgot">-->
<!--          <div class="form-wrapper" style=" margin-top: 50px;" >-->
<!--            <div class="vertical-center">-->
<!--              <div class="inner-block">-->
<!--                <h3>LOGIN to dashboard</h3>-->
<!--                <form @submit.prevent="login" action="">-->
<!--                  <div v-if="errors" class="errors">-->
<!--                    <p v-for="(error, field) in errors" :key="field">-->
<!--                      {{ error[0] }}-->
<!--                    </p>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label>Email address</label>-->
<!--                    <input type="email" v-model="username" class="form-control form-control-lg" placeholder="Enter your email"><br>-->
<!--                  </div>-->
<!--                </form>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


      </div>
      <div class="social-icons">
        <ul>
          <li><a href="#" title="Continue with Google"><i class="fab fa-google" style="color: #f26c0d"></i></a></li>
          <li><a href="#" title="Continue with Facebook"><i class="fab fa-facebook-f" style="color: #f26c0d"></i></a></li>
          <li><a href="#" title="Continue with Twitter"><i class="fab fa-twitter" style="color: #f26c0d"></i></a></li>
          <li><a href="#" title="Continue with Instagram"><i class="fab fa-instagram" style="color: #f26c0d"></i></a></li>
          <li><a href="#" title="Continue with Github"><i class="fab fa-github" style="color: #f26c0d"></i></a></li>
        </ul>
      </div>
    </form>
  </div>
  </div>
  </div>
</template>

<script>
import authService from "../services/auth.service";
import {useStore} from "@/store";
import router from "@/router";
import {toRefs, ref, reactive, onMounted} from "vue";
import {MutationType} from "@/store/mutations";

export default {
  name: "Login",
  setup() {
    const form = reactive({
      username: '',
      password: '',
      grantType: 'password',
      clientId: "myrent",
      clientSecret: "testpass",
      icon:"https://cdn3.iconfinder.com/data/icons/zwo-miscellaneous-002/64/hide_eye_close-512.png",
      typeShow:"password"
    })
    const errors = ref("")
    const store = useStore();
    const goToForgot = () => {
      router.push({name: 'forgot', params: { user: form.username} })
    };
    const  login = () => {

      authService.pingPong({hello:"world",dashboard:"me"})

      authService.auth({
        grant_type: 'password',
        username: form.username,
        password: form.password,
        client_id: "myrent",
        client_secret: "testpass",
        icon: "https://cdn3.iconfinder.com/data/icons/zwo-miscellaneous-002/64/hide_eye_close-512.png",
        typeShow: "password"
      }).then((success, errors)=>{
          if (success) {
            authService.login(form).then((success, errors) => {
              if (success) {
                router.push({name: 'tickets'});
              } else {
                errors.value = errors;
              }
            });
          }
          else {
            errors.value = errors;
          }
      })

    }
    const showPassword = ()=> {
      form.typeShow = (form.typeShow === 'password')? "text": "password";
      form.icon = (form.typeShow === 'password')?"https://cdn3.iconfinder.com/data/icons/zwo-miscellaneous-002/64/hide_eye_close-512.png":
          "https://cdn2.iconfinder.com/data/icons/font-awesome/1792/eye-512.png";
    }
    const  setupForm = () => {
          form.username= '';
          form.password= '';
          form.grantType= 'password';
          form.clientId= "myrent";
          form.clientSecret= "testpass";
    }
    onMounted(()=>{
        setupForm();
    })
    return {store, login, goToForgot,...toRefs(form), errors, showPassword};
  },
}
</script>

<style lang="scss" scoped>

* {
  box-sizing: border-box;
}

body {
  background: #ddecee !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
}

body,
html,
.App,
.vue-tempalte,
.vertical-center {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 550px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
}

.forgot-password,
.forgot-password a {
  //text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #01818c;
  margin: 0;
}

.forgot-password a {
  color: #01818c;
}

.social-icons {
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.5em;
  color: #222222;
}

.social-icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-icons ul li {
  display: inline-block;
  zoom: 1;
  width: 65px;
  vertical-align: middle;
  border: 1px solid #e3e8f9;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  background: #f4f6ff;
}

.social-icons ul li a {
  display: block;
  font-size: 1.4em;
  margin: 0 5px;
  text-decoration: none;
}
.social-icons ul li a i {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.social-icons ul li a:focus i,
.social-icons ul li a:active i {
  transition: none;
  color: #F58B3D;
}


.btn-info, .btn-primary, .btn-success {
  background-color: #01818c ;
  color: white;
}
.btn-warning {
  background-color: #F58B3D;
  color: white;
}
.btn-info:hover, .btn-info:focus,
.btn-success:hover, .btn-success:focus,
.btn-primary:hover, .btn-primary:focus {
  background-color: #01747f;
  background-position: 0 -15px;
  color: white;
}
.btn-info:active, .btn-info.active,
.btn-success:active, .btn-success.active,
.btn-primary:active, .btn-primary.active {
  background-color: #F58B3D;
}

.btn-warning:active, .btn-warning.active {
  background-color: #01818c;
  color: white;
}
.btn-warning:hover, .btn-warning:focus {
  background-color: #f26c0d;
  background-position: 0 -15px;
  color: white;
}
</style>
